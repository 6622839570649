<template>
  <div class="excelPlaceAnOrderPage">
    <!--      头部-->
    <div class="EmployeesAccountTitle flexAndCenter">
      <div class="fontSize20 color333 fontWeightBold">Excel{{ $fanyi("下单") }}</div>
    </div>
    <footer class="">
      <div class="footerTitle">エクセルアップロードで注文機能</div>
      <div class="templateDownloadContainer">
        <div class="templateDownloadTitleText">1、RAKUMART専用 発注アップロードファイルは　こちらから、ダウンロードしてください。
          　（他のフォーマットが使用しないでください；記入方法がファイルの「sample」をご参考ください。）
        </div>
        <el-link :href="excelOrderTplUrl+code" :underline="false" target="_blank">
          <div class="templateDownloadBtn">
            <img src="../../../../assets/churujinList/download.png" alt="">
            <div>{{ $fanyi("下载模板") }}</div>
          </div>
        </el-link>
      </div>
      <div class="fileUploadContainer">
        <div class="fileUploadHeaderText">２、編集できたファイルが、 こちら　でアップロードしてください。</div>
        <div class="uploadContainer flexAndCenter">
          <div class="uploadHeaderContainer flexAndCenter">
            <el-upload
                class="upload-demo"
                :action="action"
                :on-success="handleAvatarSuccess"
                :headers="headers"
                :show-file-list="false"
                drag
                :before-upload="beforeAvatarUpload">
              <img src="../../../../assets/folderIcon.png" alt="">
              <div>{{ $fanyi("点击或将EXCEL 文件拖拽到这里上传") }}</div>
            </el-upload>
          </div>
          <div class="uploadFooterContainer">
            <div>{{ $fanyi("文件格式仅限Xls和Xlsx") }}</div>
          </div>
        </div>
        <div class="fileUploadFooterText">3、ブラウザについて、グーグル・クローム推奨です、そのほかのブラウザについて、アップロードできない
          場合がございます。
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import websiteUrl from "@/api/wangZhi";

export default {
  data() {
    return {
      uploadFileList: [],
      code: '',
      action: websiteUrl + '/client/excelOrder.excelOrder',
      excelOrderTplUrl: websiteUrl + '/client/excelOrder.excelOrderTpl?rand=',
      // action: 'https://apiwww.rakumart.com/client/excelOrder.excelOrder',
      // excelOrderTplUrl: 'https://apiwww.rakumart.com/client/excelOrder.excelOrderTpl?rand=',
    }
  },
  computed: {
    headers() {
      return {
        "ClientToken": 'Bearer ' + localStorage.getItem('japan_user_token')
      }
    },
  },
  created() {
    for (var i = 0; i < 7; i++) {
      this.code += parseInt(Math.random() * 10);
    }
  },
  methods: {
    //文件上传前吹
    beforeAvatarUpload(file) {
      const isXls = ["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"].indexOf(file.type) !== -1;
      if (!isXls) {
        this.$message.error(this.$fanyi("文件格式仅限Xls和Xlsx"));
        return isXls;
      }
      this.loading = this.$loading({
        lock: true,
        text: this.$fanyi("文件上传中"),
        spinner: "el-icon-loading fontSize50",
        background: "rgba(255, 255, 255, 0.7)",
      });
    },
    //上传成功
    handleAvatarSuccess(res, file) {
      if (res.code != 0) {
        this.loading.close();
        this.$message.error(this.$fanyi(res.msg));
        return
      }
      this.uploadFileList.push({
        data: res.data,
        name: file.name
      })
      this.loading.close();
      this.$store.commit("setExcelOrderPreviewInfo", res.data)
      this.$fun.routerToPage('/excelOrderPreview?id=100&type=temporary');
    },
    //删除文件
    deleteFileList(index) {
      this.uploadFileList.splice(index, 1);
    },
  }
}
</script>

<style lang="scss" scoped>
.excelPlaceAnOrderPage {
  background: #ffffff;
  border-radius: 10px;

  .EmployeesAccountTitle {
    background: #FFFFFF;
    height: 73px;
    justify-content: space-between;
    padding: 0 26px;
    border-bottom: 1px solid #DFDFDF;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  footer {
    padding: 27px;

    .footerTitle {
      font-weight: bold;
      color: #333;
      margin-bottom: 25px;
    }

    .templateDownloadContainer {
      width: 730px;
      height: 138px;
      background: #F9F9F9;
      border: 1px solid #DFDFDF;
      border-radius: 6px;
      box-sizing: border-box;
      padding: 26px;

      .templateDownloadTitleText {
        color: #333333;
        font-size: 14px;
        margin-bottom: 20px;
      }

      .templateDownloadBtn {
        display: flex;
        align-items: center;
        height: 34px;
        background: #B4272B;
        border-radius: 8px;
        justify-content: center;
        cursor: pointer;
        padding: 0 10px;

        div {
          margin-left: 8px;
          color: #FFFFFF;
          font-size: 14px;
        }
      }
    }

    .fileUploadContainer {
      margin-top: 20px;
      width: 730px;
      height: 326px;
      background: #F9F9F9;
      border: 1px solid #DFDFDF;
      border-radius: 6px;
      box-sizing: border-box;
      padding: 26px;

      .uploadContainer {
        background: #FFFFFF;
        border: 1px solid #DFDFDF;
        border-radius: 8px;
        margin: 20px 0;
        width: 360px;
        height: 190px;
        box-sizing: border-box;
        flex-direction: column;

        .uploadHeaderContainer {
          flex-direction: column;
          cursor: pointer;

          img {
            width: 64px;
            height: 50px;
            margin-bottom: 10px;
          }

          div {
            color: #B4272B;
            font-size: 14px;
          }
        }


        .uploadFooterContainer {
          color: #999999;
          font-size: 12px;
          z-index: 9;
          margin-top: -45px;
        }
      }

      .fileUploadHeaderText, .fileUploadFooterText {
        color: #333333;
        font-size: 14px;
      }

      /deep/ .el-upload-dragger {
        border: 0;
        width: 358px;
        height: 186px;
        padding-top: 30px;
        //margin-top: -20px;
      }
    }

    .fileListContainer {
      margin: 26px 0;
    }

    .submitBtn {
      width: 200px;
      height: 34px;
      background: #B4272B;
      border-radius: 8px;
      line-height: 34px;
      text-align: center;
      cursor: pointer;
      color: #fff;
      font-size: 14px;
    }
  }
}
</style>
